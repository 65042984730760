import React from "react";
import { ShopPhotoGalleryConnectorProps } from "./container";
import { Button } from "@material-ui/core";

import "./styles.scss";
import { DesktopPopupModal, Icon, IconName, PhotoLayout } from "halifax";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";

export interface ShopPhotoGalleryProps extends ShopPhotoGalleryConnectorProps {
  isSkeleton?: boolean;
}

export const ShopPhotoGallery = (props: ShopPhotoGalleryProps) => {
  const { isSkeleton } = props;
  const [imagesArray, setImagesArray] = React.useState<string[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const imagesArrayTemp: string[] = [
    "https://media.tacdn.com/media/attractions-splice-spp-674x446/0f/88/7b/95.jpg",
    "https://media.tacdn.com/media/attractions-splice-spp-674x446/06/ef/71/79.jpg",
    "https://media.tacdn.com/media/attractions-splice-spp-674x446/0f/a9/2f/fd.jpg",
    "https://media.tacdn.com/media/attractions-splice-spp-674x446/0f/88/7b/e9.jpg",
    "https://media.tacdn.com/media/attractions-splice-spp-674x446/0f/88/7b/e8.jpg",
  ];

  React.useEffect(() => {
    setImagesArray(imagesArrayTemp);
  }, [setImagesArray]);

  const filteredImagesArray = React.useMemo(
    () =>
      // The filter method is used to remove empty items in imagesArray
      imagesArray.filter((imageTuple) => !!imageTuple),
    [imagesArray]
  );

  return (
    <div className="experiences-shop-photo-gallery-modal">
      {isSkeleton && (
        <Skeleton className="experiences-shop-photo-gallery-modal-main-image-container skeleton" />
      )}
      {!isSkeleton && (
        <div
          className="experiences-shop-photo-gallery-modal-main-image-container"
          onClick={() => {
            setOpenModal(!openModal);
          }}
        >
          <div className="experiences-shop-photo-gallery-modal-main-image-section-left">
            <img
              className="main-image-left"
              src={filteredImagesArray[0]}
              alt="experiences-1"
            />
          </div>
          <div className="experiences-shop-photo-gallery-modal-main-image-section-right">
            <div className="main-image-right top-image">
              <img src={filteredImagesArray[1]} alt="experiences-2" />
            </div>
            <div className="main-image-right bottom-image">
              <img src={filteredImagesArray[2]} alt="experiences-3" />
            </div>
          </div>
          <Button
            className="show-all-photos-button"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <Icon name={IconName.ShowAllImagesIcon} />
            <span className="show-all-photos-button-label">
              See all photos ({filteredImagesArray.length})
            </span>
          </Button>
        </div>
      )}
      <DesktopPopupModal
        className={clsx("experiences-shop-photo-gallery-modal-popup", "b2b")}
        open={openModal}
        invisibleBackdrop={false}
        onClose={(event: React.MouseEvent) => {
          event.stopPropagation();
          setOpenModal(false);
        }}
      >
        <div
          id="experiences-shop-photo-gallery-modal-container"
          className="experiences-shop-photo-gallery-modal-container"
        >
          <div id="photo-gallery" className="photo-gallery">
            <PhotoLayout
              imgUrls={filteredImagesArray}
              scrollableTarget="photo-gallery"
            />
          </div>
        </div>
      </DesktopPopupModal>
    </div>
  );
};
