export enum ExperiencesNavTab {
  GALLERY = "Gallery",
  OVERVIEW = "Overview",
  WHAT_TO_EXPECT = "What to expect",
  CANCELLATION_POLICY = "Cancellation policy",
  WHATS_INCLUDED = "What's included",
  REVIEWS = "Reviews",
  TRAVELER_PHOTOS = "Traveler photos",
  CONTACT = "Contact",
  COMPARE = "Compare",
}

export const SHOP_NAV_TABS = [
  ExperiencesNavTab.GALLERY,
  ExperiencesNavTab.OVERVIEW,
  ExperiencesNavTab.WHAT_TO_EXPECT,
  ExperiencesNavTab.CANCELLATION_POLICY,
  ExperiencesNavTab.WHATS_INCLUDED,
  ExperiencesNavTab.REVIEWS,
  ExperiencesNavTab.TRAVELER_PHOTOS,
  ExperiencesNavTab.CONTACT,
  ExperiencesNavTab.COMPARE,
];
