import React from "react";
import "./styles.scss";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ShopComponentSkeleton } from "../../component";

export interface IShopWhatsIncludedProps {
  isSkeleton: boolean;
}

export const ShopWhatsIncluded = (props: IShopWhatsIncludedProps) => {
  const { isSkeleton } = props;

  const title = "What's included";

  const renderSkeleton = () => (
    <Box className="experiences-shop-skeleton">
      <Box className="section-wrapper-container">
        <Box className={clsx("section-wrapper")}>
          <ShopComponentSkeleton name="small" />
          <ShopComponentSkeleton name="large" />
          <ShopComponentSkeleton name="medium" />
        </Box>
        <Box className={clsx("section-wrapper")}>
          <ShopComponentSkeleton name="medium" />
          <ShopComponentSkeleton name="large" />
          <ShopComponentSkeleton name="medium" />
        </Box>
        <Box className={clsx("section-wrapper")}>
          <ShopComponentSkeleton name="large" />
          <ShopComponentSkeleton name="small" />
          <ShopComponentSkeleton name="large" />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box className="experiences-shop-whats-included-container">
      <Typography className="experiences-shop-component-title">
        {title}
      </Typography>
      {isSkeleton ? renderSkeleton() : null}
    </Box>
  );
};
