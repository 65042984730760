import React, { useCallback, useEffect, useMemo, useState } from "react";

// types, constants
import { IResult, IIdExperiences, RecentHotelSearch } from "redmond";
import * as textConstants from "./textConstants";
import { ExperiencesSearchControlConnectorProps } from "./container";

// styles, components
import "./styles.scss";
import { Box } from "@material-ui/core";
import { LocationAutocomplete } from "./components/LocationAutocomplete";
import { ExperiencesSearchButton } from "./components/SearchButton";
import { BannerSeverity, Icon, IconName, NotificationBanner } from "halifax";

// helpers, utils
import clsx from "clsx";
import H from "history";
import { SEARCH_EXPERIENCES_BUTTON } from "../../textConstants";
import { CalendarPickerButton } from "./components/CalendarPickerButton";
import { setFromDate, setUntilDate } from "../../actions/actions";
import { PATH_AVAILABILITY } from "../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../../utils/queryStringHelpers";

export interface IExperiencesSearchControlProps
  extends ExperiencesSearchControlConnectorProps {
  className?: string;
  recentSearches?: RecentHotelSearch[];
  onSearch?: (
    history: H.History,
    searchFromMap?: boolean,
    searchExperiencesNear?: boolean
  ) => void;
  onRecentSearchClick?: (search: RecentHotelSearch) => void;
  showPreferencesBanner?: boolean;
  saveDatesOnClose?: boolean;
  showSearchButton?: boolean;
}

export const ExperiencesSearchControl = (
  props: IExperiencesSearchControlProps
) => {
  const {
    className,
    onSearch,
    location,
    fromDate,
    untilDate,
    saveDatesOnClose,
    showSearchButton = true,
    resetFilters,
  } = props;
  const [hasMissingSearchInfoError, setHasMissingSearchInfoError] =
    useState(false);

  const getSelectedOption = (option: IResult | null, value: IResult | null) => {
    const selection = value ? (value.id as IIdExperiences) : null;
    const opt = option ? (option.id as IIdExperiences) : null;
    return (
      !!selection &&
      !!opt &&
      selection.experiencesSelection.id === opt.experiencesSelection.id
    );
  };

  const isReadyToSearch = useMemo(
    () => !!location && !!fromDate && !!untilDate,
    [location, fromDate, untilDate]
  );

  useEffect(() => {
    if (isReadyToSearch) {
      setHasMissingSearchInfoError(false);
    }
  }, [isReadyToSearch]);

  const handleSearchClick = useCallback(
    (history: H.History) => {
      if (isReadyToSearch) {
        history.push(
          `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
            (location?.id as IIdExperiences).experiencesSelection.searchTerm,
            fromDate,
            untilDate
          )}`
        );

        onSearch && onSearch(history, false);
        resetFilters();
      }

      setHasMissingSearchInfoError(true);
    },
    [isReadyToSearch]
  );

  return (
    <Box className={clsx("experiences-search-control-root", className)}>
      <Box className={"experiences-search-control-row"}>
        <Box className="experiences-search-inputs">
          <Box className={"experiences-search-input-row"}>
            <LocationAutocomplete
              className="destination-auto-complete b2b"
              label={textConstants.LOCATION_AUTOCOMPLETE_PLACEHOLDER}
              getOptionSelected={getSelectedOption}
              customIcon={
                <Icon name={IconName.B2BMapPin} ariaLabel="" aria-hidden />
              }
              hasMissingSearchInfoError={hasMissingSearchInfoError && !location}
            />
            <Box className={"date-input"}>
              <CalendarPickerButton
                classes={["date-pickers"]}
                fromDate={fromDate}
                untilDate={untilDate}
                setFromDate={setFromDate}
                setUntilDate={setUntilDate}
                saveDatesOnClose={saveDatesOnClose}
                hasMissingSearchInfoError={
                  hasMissingSearchInfoError && !fromDate && !untilDate
                }
              />
            </Box>
          </Box>
        </Box>
        {showSearchButton && (
          <ExperiencesSearchButton
            className="experiences-search-control-button b2b"
            message={SEARCH_EXPERIENCES_BUTTON}
            onClick={(history: H.History) => {
              handleSearchClick(history);
            }}
          />
        )}
      </Box>
      {hasMissingSearchInfoError && (
        <Box className="missing-info-search-error-container">
          <NotificationBanner
            className="missing-info-search-error-banner"
            label={textConstants.MISSING_INFO_SEARCH_ERROR}
            severity={BannerSeverity.ERROR}
            icon={<Icon name={IconName.WarningAlert} />}
          />
        </Box>
      )}
    </Box>
  );
};
