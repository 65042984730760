import clsx from "clsx";
import { ActionLink } from "halifax";
import React, { useState } from "react";
import "./styles.scss";
import {
  ExperiencesNavTab,
  SHOP_NAV_TABS,
} from "../ShopNavigationTabBar/constants";

export interface IShopNavigationTabButtonProps {
  tabLabel: ExperiencesNavTab;
  activeTab: ExperiencesNavTab;
  onClick: (tab: ExperiencesNavTab) => void;
}

export const ShopNavigationTabButtons = ({}: {}) => {
  const [activeNavTab, setActiveNavTab] = useState<ExperiencesNavTab>(
    ExperiencesNavTab.GALLERY
  );

  return (
    <>
      {SHOP_NAV_TABS.map((navTab: ExperiencesNavTab) => {
        return (
          <ShopNavigationTabButton
            key={navTab}
            tabLabel={navTab}
            activeTab={activeNavTab}
            onClick={setActiveNavTab}
          />
        );
      })}
    </>
  );
};

export const ShopNavigationTabButton = (
  props: IShopNavigationTabButtonProps
) => {
  const { tabLabel, activeTab, onClick } = props;

  const handleOnClick = () => {
    onClick(tabLabel);
    console.log(`activeTab: ${activeTab}, tabLabel: ${tabLabel}`);
  };

  return (
    <ActionLink
      className={clsx(
        "experiences-shop-navigation-tab-bar-button",
        { selected: tabLabel === activeTab },
        "b2b"
      )}
      onClick={handleOnClick}
      content={tabLabel}
      disabled={false}
    />
  );
};
