import React from "react";
import "./styles.scss";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ShopComponentSkeleton } from "../../component";

export interface IShopCancellationPolicyProps {
  isSkeleton: boolean;
}

export const ShopCancellationPolicy = (props: IShopCancellationPolicyProps) => {
  const { isSkeleton } = props;

  const title = "Cancellation policy";

  const renderSkeleton = () => (
    <Box className="experiences-shop-skeleton">
      <Box className="section-wrapper-container">
        <Box className={clsx("section-wrapper")}>
          <ShopComponentSkeleton name="small" />
          <ShopComponentSkeleton name="large" />
          <ShopComponentSkeleton name="medium" />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box className="experiences-shop-cancellation-policy-container">
      <Typography className="experiences-shop-component-title">
        {title}
      </Typography>
      {isSkeleton ? renderSkeleton() : null}
    </Box>
  );
};
