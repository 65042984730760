import { connect, ConnectedProps } from "react-redux";

import { AvailabilitySearchControl } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { areRequestParametersChanged } from "../../reducer";
import {
  getTravelWalletCredit,
  getTravelWalletOffers,
} from "../../../travel-wallet/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    areRequestParametersChanged: areRequestParametersChanged(state),
    walletItemCount: getTravelWalletCredit(state)
      ? getTravelWalletOffers(state).length + 1
      : getTravelWalletOffers(state).length,
  };
};

const mapDispatchToProps = () => {
  return {
    resetFilters: () => {},
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilitySearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilitySearchControl = connector(
  AvailabilitySearchControl
);
