import { Box } from "@material-ui/core";
import React from "react";
import "./styles.scss";
import { ShopNavigationTabButtons } from "../ShopNavigationTabButton/component";

export const ShopNavigationTabBar = () => {
  return (
    <div className="experiences-shop-navigation-tab-bar-root">
      <Box className="experiences-shop-navigation-tab-bar-container">
        <ShopNavigationTabButtons />
      </Box>
    </div>
  );
};
