import React, { useState } from "react";
import { CloseButtonIcon, Header, ActionLink } from "halifax";
import { Box, Typography } from "@material-ui/core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";
import { MobileExperiencesSearchConnectorProps } from "./container";
import "./styles.scss";
import { PATH_AVAILABILITY, PATH_HOME } from "../../../../utils/paths";
import * as textConstants from "../../../common/textConstants";
import { LocationSearch, MobileCalendarPicker } from "../../../common";
import H from "history";
import { transformToStringifiedAvailabilityQuery } from "../../../../utils/queryStringHelpers";
import { IIdExperiences } from "redmond";

enum MobileExperiencesSearchStep {
  LocationSearch,
  CalendarPicker,
  Complete,
}

export interface IMobileExperiencesSearchControlProps
  extends MobileExperiencesSearchConnectorProps,
    RouteComponentProps {
  onSelectLocation?: (value: any) => void;
  onSearch?: (
    history: H.History,
    searchFromMap?: boolean,
    searchExperiencesNear?: boolean
  ) => void;
}

export const MobileExperiencesSearchControl = (
  props: IMobileExperiencesSearchControlProps
) => {
  const {
    history,
    searchLocation,
    locationCategories,
    loading,
    hasLocationAutocompleteError,
    setLocationAutocompleteError,
    onSelectLocation,
    setLocation,
    fetchLocationCategories,
    onSearch,
  } = props;

  const [from, setFrom] = useState<Date | null>(null);
  const [until, setUntil] = useState<Date | null>(null);

  const [currentStep, setCurrentStep] =
    React.useState<MobileExperiencesSearchStep>(
      MobileExperiencesSearchStep.LocationSearch
    );

  const handleGoBack = () => {
    if (currentStep === MobileExperiencesSearchStep.LocationSearch) {
      handleClose();
    }
    setCurrentStep((step) => {
      return step > MobileExperiencesSearchStep.LocationSearch
        ? step - 1
        : step;
    });
  };

  React.useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [currentStep]);

  const handleClose = () => {
    history.push(PATH_HOME);
    setLocation(null);
    setFrom(null);
    setUntil(null);
    hasLocationAutocompleteError && setLocationAutocompleteError(false);
  };

  const handleSearch = (history: H.History) => {
    history.push(
      `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
        (searchLocation?.id as IIdExperiences).experiencesSelection.searchTerm,
        from,
        until
      )}`
    );
    onSearch && onSearch(history, false);
  };

  return (
    <Box
      className={clsx({
        "date-range-picker":
          currentStep === MobileExperiencesSearchStep.CalendarPicker,
      })}
    >
      <Header
        className="homepage"
        center={<HeaderCenterSection currentStep={currentStep} />}
        left={
          <ActionLink
            className={clsx("mobile-experiences-search-header-go-back", {
              hidden:
                currentStep === MobileExperiencesSearchStep.LocationSearch,
            })}
            onClick={handleGoBack}
            content={<FontAwesomeIcon icon={faChevronLeft} />}
          />
        }
        right={
          <ActionLink
            className="mobile-experiences-search-header-close"
            onClick={handleClose}
            content={<CloseButtonIcon />}
            label="Close"
          />
        }
        isMobile={true}
        fullWidth={true}
      />
      {currentStep === MobileExperiencesSearchStep.LocationSearch && (
        <>
          <LocationSearch
            onComplete={(value: any) => {
              setCurrentStep(MobileExperiencesSearchStep.CalendarPicker);
              onSelectLocation?.(value);
            }}
            locationSearchPrefixString={textConstants.WHERE_TO}
            onClickAutocompleteError={() => {
              history.push(PATH_HOME);
              hasLocationAutocompleteError &&
                setLocationAutocompleteError(false);
            }}
            location={searchLocation}
            locationCategories={locationCategories}
            loading={loading}
            hasLocationAutocompleteError={hasLocationAutocompleteError}
            setLocation={setLocation}
            fetchLocationCategories={fetchLocationCategories}
            setLocationAutocompleteError={setLocationAutocompleteError}
          />
        </>
      )}
      {currentStep === MobileExperiencesSearchStep.CalendarPicker && (
        <MobileCalendarPicker
          fromDate={from}
          untilDate={until}
          setFromDate={setFrom}
          setUntilDate={setUntil}
          handleSearch={handleSearch}
        />
      )}
    </Box>
  );
};

interface IHeaderCenterSectionProps {
  currentStep: number;
}

const HeaderCenterSection = ({ currentStep }: IHeaderCenterSectionProps) => {
  const headerText =
    currentStep === MobileExperiencesSearchStep.CalendarPicker
      ? textConstants.UPDATE_CALENDAR_TEXT
      : textConstants.EXPERIENCES_HEADER;
  return (
    <Box className="header-center-section">
      {headerText === textConstants.UPDATE_CALENDAR_TEXT ? (
        <Box className="calendar-header">
          <Typography variant="body1">{headerText}</Typography>
        </Box>
      ) : (
        <Box className="mobile-experiences-search-location-label">
          <Typography variant="body1">{headerText}</Typography>
        </Box>
      )}
    </Box>
  );
};
