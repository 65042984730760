import { Box, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { RouterProps } from "react-router";
import { ExperiencesShopConnectorProps } from "./container";
import clsx from "clsx";
import { ActionButton, Header, useDeviceTypes } from "halifax";
import "./styles.scss";
import { RewardsAccountSelection } from "../rewards/components";
import { BACK_TO_RESULTS, SHOP_DETAIL_HEADER_TEXT } from "./textConstants";
import { TravelWalletDrawer } from "../travel-wallet/components";
import { ShopPhotoGallery } from "./components/ShopPhotoGallery";
import { ShopNavigationTabBar } from "./components/ShopNavigationTabBar/component";
import { ShopOverview } from "./components/ShopOverview/component";
import { ShopWhatToExpect } from "./components/ShopWhatToExpect/component";
import { ShopCancellationPolicy } from "./components/ShopCancellationPolicy/component";
import { ShopWhatsIncluded } from "./components/ShopWhatsIncluded/component";
import { ShopCustomerReviews } from "./components/ShopCustomerReviews/component";
import { ShopTravelerPhotos } from "./components/ShopTravelerPhotos/component";
import { ShopContactInformation } from "./components/ShopContactInformation/component";
import { ShopCheckoutBreakdown } from "./components/ShopCheckoutBreakdown/component";
import { ShopEarnInfo } from "./components/ShopEarnInfo/component";
import { ShopCompare } from "./components/ShopCompare/component";
import Skeleton from "react-loading-skeleton";

export interface IExperiencesShop
  extends ExperiencesShopConnectorProps,
    RouterProps {}

export const ExperiencesShop = (props: IExperiencesShop) => {
  const { history, searchLocation, experienceName, fetchExperiencesShop } =
    props;

  const headerSubtitleText = "By Default City Wonders Ltd";

  const { matchesMobile } = useDeviceTypes();

  const isSkeleton = true;

  useEffect(() => {
    fetchExperiencesShop({
      history: history,
    });
  }, []);

  const renderDesktopHeaderView = () => {
    return (
      <Box className="experiences-shop-container">
        <Header
          className="rewards-components-section"
          left={
            <Box className={"rewards-account-section-left-content"}>
              <ActionButton
                className="back-to-results"
                message={BACK_TO_RESULTS}
                onClick={() => {
                  // returnToAvailability();
                }}
                defaultStyle="h4r-secondary"
              />
              <Box className={"rewards-account-section-travel-details"}>
                <Typography variant={"body1"} tabIndex={0}>
                  {SHOP_DETAIL_HEADER_TEXT(
                    experienceName ? experienceName : "",
                    searchLocation
                      ? searchLocation.label
                      : "Default Paris, France"
                  )}
                </Typography>
                <Typography variant={"body2"} tabIndex={0}>
                  {headerSubtitleText}
                </Typography>
              </Box>
            </Box>
          }
          right={
            <Box className="desktop-experiences-shop-rewards-account-contents">
              <RewardsAccountSelection
                className={clsx("b2b hide-balance-border")}
                popoverClassName="b2b"
              />
              <TravelWalletDrawer />
            </Box>
          }
        />
        {/* Update isSkeleton based on initial shop call state. Setting Gallery as false to display example photos */}
        <ShopPhotoGallery isSkeleton={false} />
        <ShopNavigationTabBar />
        <Box className={"experiences-shop-split-view-container"}>
          <Box className={"experiences-shop-split-view-section"}>
            <Box className={"experiences-shop-left-section"}>
              <ShopOverview isSkeleton={isSkeleton} />
              <ShopWhatToExpect isSkeleton={isSkeleton} />
              <ShopCancellationPolicy isSkeleton={isSkeleton} />
              <ShopWhatsIncluded isSkeleton={isSkeleton} />
              <ShopCustomerReviews isSkeleton={isSkeleton} />
              <ShopTravelerPhotos isSkeleton={isSkeleton} />
              <ShopContactInformation isSkeleton={isSkeleton} />
            </Box>
            <Box className={"experiences-shop-right-section"}>
              <ShopCheckoutBreakdown isSkeleton={isSkeleton} />
              <ShopEarnInfo isSkeleton={isSkeleton} />
            </Box>
          </Box>
          <ShopCompare isSkeleton={isSkeleton} />
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        className={clsx("experiences-shop-root", {
          "full-width": !matchesMobile,
        })}
      >
        {renderDesktopHeaderView()}
        <Box className="experiences-shop-container"></Box>
      </Box>
    </>
  );
};

export interface IShopComponentSkeletonProps {
  name: string;
}
export const ShopComponentSkeleton = (props: IShopComponentSkeletonProps) => {
  return (
    <Skeleton className={clsx("experiences-shop-skeleton", `${props.name}`)} />
  );
};
