import { Box, Badge, Typography } from "@material-ui/core";
import {
  ActionLink,
  BackButton,
  CloseButtonIcon,
  DatePickerButton,
  Header,
  Icon,
  IconName,
  MobilePopoverCard,
  MobileSearchFieldButton,
} from "halifax";
import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import H from "history";

import { PATH_AVAILABILITY, PATH_HOME } from "../../../../utils/paths";

import { MobileAvailabilitySearchControlConnectorProps } from "./container";
import { TravelWalletDrawer } from "../../../travel-wallet/components";
import "./styles.scss";
import { LocationSearch, MobileCalendarPicker } from "../../../common";
import { transformToStringifiedAvailabilityQuery } from "../../../../utils/queryStringHelpers";
import { IIdExperiences } from "redmond";
import * as textConstants from "../../../common/textConstants";

export interface IMobileAvailabilitySearchControlProps
  extends RouteComponentProps,
    MobileAvailabilitySearchControlConnectorProps {}

export const MobileAvailabilitySearchControl = (
  props: IMobileAvailabilitySearchControlProps
) => {
  const {
    history,
    walletItemCount,
    fromDate,
    untilDate,
    searchLocation,
    locationCategories,
    loading,
    hasLocationAutocompleteError,
    setLocation,
    fetchLocationCategories,
    setLocationAutocompleteError,
  } = props;
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [calendarModalOpen, setCalendarModalOpen] = useState(false);
  const [travelWalletDrawerOpen, setTravelWalletDrawerOpen] = useState(false);

  const [from, setFrom] = useState<Date | null>(null);
  const [until, setUntil] = useState<Date | null>(null);

  useEffect(() => {
    setFrom(fromDate);
  }, [fromDate]);

  useEffect(() => {
    setUntil(untilDate);
  }, [untilDate]);

  const handleSearch = (history: H.History, newLocation?: any) => {
    const searchTerm = newLocation
      ? (newLocation?.id as IIdExperiences).experiencesSelection.searchTerm
      : (searchLocation?.id as IIdExperiences).experiencesSelection.searchTerm;

    history.push(
      `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
        searchTerm,
        from,
        until
      )}`
    );
    if (calendarModalOpen) setCalendarModalOpen(false);
    if (locationModalOpen) setLocationModalOpen(false);
  };

  return (
    <>
      <Header
        className="mobile-availability-search-header"
        left={
          <BackButton
            className="mobile-availability-go-back"
            onClick={() => history.push(PATH_HOME)}
          />
        }
        center={
          <Box className="mobile-availability-search-and-date">
            <MobileSearchFieldButton
              value={searchLocation?.label}
              onClick={() => setLocationModalOpen(true)}
            />
            <DatePickerButton
              startDate={from}
              endDate={until}
              renderCalendarPopup={() => (
                <MobilePopoverCard
                  open={calendarModalOpen}
                  className="mobile-search-calendar-popup"
                  onClose={() => setCalendarModalOpen(false)}
                  headerElement={
                    <Box className="modal-header-container">
                      <Typography className="header-title">
                        {textConstants.UPDATE_CALENDAR_TEXT}
                      </Typography>
                      <ActionLink
                        className="modal-close-button"
                        onClick={() => setCalendarModalOpen(false)}
                        content={
                          <CloseButtonIcon className="close-button-icon" />
                        }
                        label="Close"
                        showTappableArea
                      />
                    </Box>
                  }
                  fullScreen
                >
                  <MobileCalendarPicker
                    fromDate={from}
                    untilDate={until}
                    setFromDate={setFrom}
                    setUntilDate={setUntil}
                    handleSearch={handleSearch}
                  />
                </MobilePopoverCard>
              )}
              onClick={() => setCalendarModalOpen(true)}
              dateFormat="MMM D"
              variant="minimal"
            />
          </Box>
        }
        right={
          <ActionLink
            className="mobile-availability-wallet-entry"
            onClick={() => setTravelWalletDrawerOpen(true)}
            content={
              <Badge
                className="wallet-count"
                badgeContent={walletItemCount ? "" : undefined}
              >
                <Icon name={IconName.TravelWalletIcon} />
              </Badge>
            }
          />
        }
      />
      <MobilePopoverCard
        open={locationModalOpen}
        className="mobile-search-location-popup"
        onClose={() => setLocationModalOpen(false)}
        headerElement={
          <Box className="modal-header-container">
            <Typography className="header-title">
              {textConstants.WHERE_TO}
            </Typography>
            <ActionLink
              className="modal-close-button"
              onClick={() => setLocationModalOpen(false)}
              content={<CloseButtonIcon className="close-button-icon" />}
              label="Close"
              showTappableArea
            />
          </Box>
        }
        fullScreen
      >
        <LocationSearch
          locationSearchPrefixString={textConstants.WHERE_TO}
          onComplete={(newLocation: any) => {
            handleSearch(history, newLocation);
          }}
          onClickAutocompleteError={() => {
            history.push(PATH_HOME);
            hasLocationAutocompleteError && setLocationAutocompleteError(false);
          }}
          location={searchLocation}
          locationCategories={locationCategories}
          loading={loading}
          hasLocationAutocompleteError={hasLocationAutocompleteError}
          setLocation={setLocation}
          fetchLocationCategories={fetchLocationCategories}
          setLocationAutocompleteError={setLocationAutocompleteError}
          continueOnSelect={false}
        />
      </MobilePopoverCard>
      <TravelWalletDrawer
        drawerOpen={travelWalletDrawerOpen}
        setDrawerOpen={setTravelWalletDrawerOpen}
      />
    </>
  );
};
